const colors = require('tailwindcss/colors');

module.exports = {
  purge: [],
  darkMode: false, // or 'media' or 'class'
  theme: {
    extend: {
      spacing: {
        '60v': '60vh'
      },
      screens: {
        'print': { 'raw': 'print' }, // => @media print { ... }
      }
    },
    boxShadow: {
      sm: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
      DEFAULT: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
      md: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
      lg: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
      xl: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
      '2xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
      '3xl': '0 35px 60px -15px rgba(0, 0, 0, 0.3)',
      inner: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)',
      none: 'none',
      'hover': '0 0 0 4px rgba(106, 119, 128, 0.1)',
      'selected': '0 0 0 4px rgba(106, 119, 128, 0.2)',
      'current': '0 0 0 4px rgba(244, 34, 114, 0.2)'
    },
    colors: {
      transparent: 'transparent',
      current: 'currentColor',
      black: '#000000',
      white: '#ffffff',
      pink: { // Customized 'pink' palette
        50: '#FCF2F6',
        100: '#F9E5ED',
        200: '#F9CFE1',
        300: '#F7A9C9',
        400: '#F87AAA',
        500: '#F64E8E',
        600: '#F42272', // Primary brand colour
        700: '#CA1E5F', // Button hover colour
        800: '#9B184D',
        900: '#821944',
      },
      violet: { // Customized 'violet' palette
        50: '#F3F3FF',
        100: '#EAE9FE',
        200: '#D7D6FE',
        300: '#B7B5FD',
        400: '#9494FF',
        500: '#6F6FEF',
        600: '#5954D6', // Possible 'medium risk'
        700: '#413FB3',
        800: '#302D80',
        900: '#2B2966',
      },
      gray: colors.blueGray,
      red: colors.red,
      rose: colors.rose,
      orange: colors.orange,
      amber: colors.amber,
      emerald: colors.emerald,
      teal: colors.teal,
      cyan: {
        ...colors.cyan,        
        800: '#1F4657',
        900: '#0F2833',
      },
      sky: colors.sky,
    },
    fontFamily: {
      sans: ['Inter']
    }
  },
  variants: {
    extend: {
      opacity: ['disabled'],
    },
  },
  plugins: [
    require('@tailwindcss/forms'),
  ],
};
