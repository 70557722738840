
// eslint-disable-next-line
import { PropType } from 'vue';
// eslint-disable-next-line
import { Series } from '@/models/apex-charts';

export default {
  name: 'ApexChartsGraph',
  props: {
    options: {
      type: Object,
      required: true
    },
    series: {
      type: Object as PropType<Series>,
      required: true,
    },
    height: {
      type: Number,
      required: false,
    }
  },
};
