// https://apexcharts.com/docs/series/
type SingleValueSeriesData = { data: number[] };
type NumericPairedValueSeriesData = { data: number[][] };
type NumericPairedValueSeriesDataXYProperties = { x: number, y: number };
type CategoryPairedValueSeriesData = { x: string, y: number };

interface Series {
  name?: string
  data: (SingleValueSeriesData | NumericPairedValueSeriesData | NumericPairedValueSeriesDataXYProperties | CategoryPairedValueSeriesData)
}

interface TooltipContextConfig {
  series: { name: string, data: {x: number, y: number}[] }[]
  xaxis: any
  customData: { currentConfiguration: number, selectedConfiguration: number }
}

class TooltipContext {
  config: TooltipContextConfig
  dataPointIndex: number

  constructor(config: TooltipContextConfig, dataPointIndex: number) {
    this.config = config;
    this.dataPointIndex = dataPointIndex;
  }
}

class TooltipItem {
  index: number
  name: string
  data: number[]

  constructor(index: number, name: string, data: number[]) {
    this.index = index;
    this.name = name;
    this.data = data;
  }

  build(currentConfiguration: number, selectedConfiguration: number, dataPointIndex: number): string {
    let element = '<li class="flex justify-between items-center py-1">';

    if (this.index === currentConfiguration) element = `${element}<span class="font-semibold text-pink-500">${this.name}</span>`;
    else if (this.index === selectedConfiguration) element = `${element}<span class="text-sm font-semibold">${this.name}</span>`;
    else element = `${element}<span class="text-sm">${this.name}</span>`;

    const statusIndicator = this.data[dataPointIndex] <= 1 ?  '<div class="text-xs text-teal-500">' : '<div class="text-xs text-orange-400">';
    const circleSVG = `
      <svg class="h-3 w-3 fill-current rounded-full border-2 border-white" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
          <circle cx="5" cy="5" r="5" />
      </svg>
    `;

    return `${element}${statusIndicator}${circleSVG}</div></li>`;
  }
}

class Tooltip {
  context: TooltipContext

  constructor(context: TooltipContext) {
    this.context = context;
  }

  get items(): TooltipItem[] {
    return this.context.config.series.map((item, index) => new TooltipItem(index, item.name, item.data.map(xy => xy.y)));
  }

  get title(): string {
    const xaxisLabel = new Date(this.context.config.xaxis.categories[this.context.dataPointIndex]).toLocaleDateString(undefined, {month: 'long', day: 'numeric', year: 'numeric'});
    return `<div class="text-gray-500 text-sm mb-2">${xaxisLabel}</div><ul>`;
  }

  sortDescending(): TooltipItem[] {
    return this.items.sort((a, b) => {
      const dataIndex = this.context.dataPointIndex;
      return b.data[dataIndex] - a.data[dataIndex];
    });
  }
  
  build() {
    let element = `
      <div class="text-gray-800 w-48 inline-block px-4 pt-4 pb-2 border border-grey-800 drop-shadow-md bg-white rounded-md">
      ${this.title}
    `;

    element = this.sortDescending()
      .map(item => item.build(this.context.config.customData.currentConfiguration, this.context.config.customData.selectedConfiguration, this.context.dataPointIndex))
      .reduce((tooltipElement, itemElement) => `${tooltipElement}${itemElement}`, element);

    return `${element}</div>`;
  }
}

function customTooltip({_, dataPointIndex, w}: any) {
  const tooltip = new Tooltip(new TooltipContext(w.config, dataPointIndex));
  return tooltip.build();
}

export {
  SingleValueSeriesData,
  NumericPairedValueSeriesData,
  NumericPairedValueSeriesDataXYProperties,
  CategoryPairedValueSeriesData,
  Series,
  customTooltip
};