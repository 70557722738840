const colors = require('../../tailwind.config.js').theme.colors;

export const DROP_SHADOW_DEFAULT_OPTIONS = {
  enabled: true,
  color: colors.pink[600],
  blur: 1,
  opacity: 0.2
};

export const DATE_XAXIS = {
  type: 'datetime',
  labels: { format: 'MMM yyyy' },
  tooltip: { enabled: false }
};

export const YAXIS_MAX = 5;
export const YAXIS_MIN = 0;
export const BASELINE = 1;

export const BASE_OPTIONS = {
  legend: { show: false },
  dataLabels: { enabled: false },
  xaxis: DATE_XAXIS,
  yaxis: {
    labels: { show: false },
    min: YAXIS_MIN,
    max: YAXIS_MAX,
    tickAmount: YAXIS_MAX
  },
  chart: {
    type: 'line',
    toolbar: { show: false },
    zoom: { enabled: false },
    animations: { enabled: false },
    dropShadow: DROP_SHADOW_DEFAULT_OPTIONS
  },
  stroke: { curve: 'smooth' },
  fill: { type: 'solid' },
};

export const DEFAULT_SERIES = {
  colour: colors.gray[400],
  width: 1
};
export const SELECTED_SERIES = {
  colour: colors.gray[500],
  width: 2
};
export const CURRENT_SERIES = {
  colour: colors.pink[600],
  width: 2
};

export const PREPARED = colors.teal[500];
export const CAUTION = colors.orange[500];

