<template>
  <div>
    <apex-charts-graph :series="series" :options="options"></apex-charts-graph>
  </div>
</template>

<script>
import ApexChartsGraph from './ApexChartsGraph.vue';
import {
  BASE_OPTIONS,
  DEFAULT_SERIES,
  SELECTED_SERIES,
  CURRENT_SERIES,
  PREPARED,
  CAUTION,
  YAXIS_MAX,
  YAXIS_MIN,
  BASELINE
} from '@/constants/time-series-graph.ts';

import { customTooltip } from '@/models/apex-charts.ts';

export default {
  name: 'DashboardSpaceTimeSeriesGraph',
  components: {
    ApexChartsGraph,
  },
  props: {
    selectedConfiguration: {
      type: Number,
      required: true
    },
    currentConfiguration: {
      type: Number,
      required: true
    },
    data: {
      type: Array,
      required: true
    }
  },
  computed: {
    xaxisCategories() {
      if (!this.series.length) return [];
      return this.series[0].data.map(xy => xy.x);
    },
    options() {
      const strokeWidths = Array(this.series.length).fill(DEFAULT_SERIES.width);
      strokeWidths[this.currentConfiguration] = CURRENT_SERIES.width;
      strokeWidths[this.selectedConfiguration] = SELECTED_SERIES.width;

      const colors = Array(this.series.length).fill(DEFAULT_SERIES.colour);
      colors[this.currentConfiguration] = CURRENT_SERIES.colour;
      colors[this.selectedConfiguration] = SELECTED_SERIES.colour;

      const baseOptions = {...BASE_OPTIONS};
      baseOptions.xaxis.categories = this.xaxisCategories;
      baseOptions.chart.dropShadow.enabledOnSeries = [this.currentConfiguration];
      baseOptions.stroke.width = strokeWidths;

      return {...baseOptions, ...{
        customData: {
          currentConfiguration: this.currentConfiguration,
          selectedConfiguration: this.selectedConfiguration,
        },
        tooltip: {
          custom: customTooltip
        },
        colors: colors,
        annotations: {
          yaxis: [
            {
              y: YAXIS_MIN,
              y2: BASELINE,
              borderColor: PREPARED,
              fillColor: PREPARED,
              width: '1%',
              opacity: 1,

            },
            {
              y: BASELINE,
              y2: YAXIS_MAX,
              borderColor: CAUTION,
              fillColor: CAUTION,
              width: '1%',
              opacity: 1,

            }
          ]
        }}};
    },
    series() {
      if (!this.data) return [];
      return this.data.map(obj => ({name: obj.name, data: this.capSeriesData(obj.data, YAXIS_MAX)}) );
    }
  },
  methods: {
    capSeriesData(data, cap) {
      return data.map(xy => ({x: xy.x, y: Math.min(xy.y, cap)}));
    }
  }
};
</script>

<style scoped>
</style>
